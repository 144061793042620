




























































































































































































































































































import { Component, Emit, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { Countries } from '../utils/data/Countries.js';

@Component
export default class Home extends Vue {
  email = ''
  emailRules = [
    (v: string) => !!v || 'Email is required',
    (v: string) => /[a-zA-Z0-9._]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}/.test(v) || 'Email invalid. Ex. sample@email.com',
  ]
  valid = true
  loading = false
  alertE = false
  alertS = false
  slides = [{
    authorId: "cli_01",
    comment: "Punctuality and Quality. You started off on the right foot!",
    author: "Jorge Galaz, Sodimac",
    image: "cli_01.png"
  }, /*{
    authorId: "cli_02",
    comment: "Good times, quality, commitment and technical skills. What I expected!",
    author: "Christian Real, Cuponatic",
    image: "cli_02.png"
  },*/ {
    authorId: "cli_03",
    comment: "You were recommended, I hope everything goes well... We loved the work!",
    author: "Patrick Real, Chita",
    image: "cli_03.png"
  }, /*{
    authorId: "cli_04",
    comment: "Delivering value to the customer every day. Thanks for the effort!",
    author: "Julio Arriagada, Karibu",
    image: "cli_04.png"
  },*/ {
    authorId: "cli_05",
    comment: "We have already been through bad experiences, we trust you... Thanks for your help!",
    author: "Pablo Ortigueira, Joyeria Turmalina",
    image: "cli_05.png"
  }, {
    authorId: "cli_06",
    comment: "The first of many projects. At the beginning there is always uncertainty, but I am glad I hired you.",
    author: "Josephine Mellozzi, AT&T",
    image: "cli_06.png"
  }, /*{
    authorId: "cli_07",
    comment: "From paper to reality :), Thanks for your help!",
    author: "Ashley Tupin, The Home Depot",
    image: "cli_07.png"
  },*/ {
    authorId: "cli_08",
    comment: "AWESOME!!! that was fast!! I thought it would be more involved. Everything looks great!",
    author: "Joseph Holmes, Conexus Technology",
    image: "cli_08.png"
  }, /*{
    authorId: "cli_09",
    comment: "The new widget looks Great! Thanks again.",
    author: "Kenneth Shafer, Gateway Mobile",
    image: "cli_09.png"
  }, {
    authorId: "cli_10",
    comment: "Thanks! You managed to develop the idea quickly, I thought it would take longer.",
    author: "Jorge Avendaño, Mandados",
    image: "cli_10.png"
  },*/ {
    authorId: "cli_11",
    comment: "This is absolutely awesome! And it is going to save my team literally hundreds of hours of time compiling and comparing lists.",
    author: "Corey Black, Green Light Tours",
    image: "cli_11.png"
  }, {
    authorId: "cli_12",
    comment: "We need an MVP ASAP... Great job!",
    author: "Rodolfo Cuadros, Sellers n Bloggers",
    image: "cli_12.png"
  }/*, {
    authorId: "cli_13",
    comment: "Guys, that looks very cool! I love it!",
    author: "Anneke Hurter, AH Design",
    image: "cli_13.png"
  }*/]
  langCode = ''
  country = {}

  mounted() {
    this.getCountry();
  }

  getCountry(): void {
    axios.get('https://jsonip.com/?callback=?')
    .then(res => {
      const data = res.data.replace('?(', '').replace(');', '');
      const jsonData = JSON.parse(data);
      const url = `https://api.softguaro.com/countries/${jsonData.ip}`;
      axios.get(url)
      .then(res => {
        const myCountry = Countries.filter((item) => item.isoCode2 === res.data.countryCode);
        this.country = (myCountry.length > 0) ? { ...myCountry[0], ipApiData: res.data } : {};
        this.langCode = (myCountry.length > 0) ? myCountry[0].lang : 'en';
      })
      .catch(error => console.error('Error: ', error));
    })
    .catch(error => console.error('Error: ', error));
  }

  @Emit()
  validForm() {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  @Emit()
  createEmailContact() {
    if (this.validForm()) {
      this.loading = true;

      const url = 'https://api.softguaro.com/contacts';
      const data = {
        email: this.email,
        country: this.country,
        lang: this.langCode,
        type: 'email'
      };

      axios.post(url, data)
      .then(() => {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        this.loading = false;
        this.alertS = true;
      })
      .catch(() => {
        this.loading = false;
        this.alertE = true;
      });
    }
  }

  @Emit()
  getImage(img) {
    const images = require.context('../assets/clients/', false, /\.png$/i)
    return images(`./${img}`)
  }
}
