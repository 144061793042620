












import { Component, Vue } from 'vue-property-decorator';
import Header from '@/views/layouts/Header.vue';
import LangSwitch from '@/components/LangSwitch.vue';

@Component({ components: { Header, LangSwitch } })
export default class AppMain extends Vue {}
