export const Countries = [
  { country: "Afghanistan", phoneCode: "93", isoCode2: "AF", isoCode3: "AFG", lang: "en" },
  { country: "Albania", phoneCode: "355", isoCode2: "AL", isoCode3: "ALB", lang: "en" },
  { country: "Algeria", phoneCode: "213", isoCode2: "DZ", isoCode3: "DZA", lang: "en" },
  { country: "American Samoa", phoneCode: "1 684", isoCode2: "AS", isoCode3: "ASM", lang: "en" },
  { country: "Andorra", phoneCode: "376", isoCode2: "AD", isoCode3: "AND", lang: "en" },
  { country: "Angola", phoneCode: "244", isoCode2: "AO", isoCode3: "AGO", lang: "en" },
  { country: "Anguilla", phoneCode: "1 264", isoCode2: "AI", isoCode3: "AIA", lang: "en" },
  { country: "Antarctica", phoneCode: "672", isoCode2: "AQ", isoCode3: "ATA", lang: "en" },
  { country: "Antigua and Barbuda", phoneCode: "1 268", isoCode2: "AG", isoCode3: "ATG", lang: "en" },
  { country: "Argentina", phoneCode: "54", isoCode2: "AR", isoCode3: "ARG", lang: "es" },
  { country: "Armenia", phoneCode: "374", isoCode2: "AM", isoCode3: "ARM", lang: "en" },
  { country: "Aruba", phoneCode: "297", isoCode2: "AW", isoCode3: "ABW", lang: "en" },
  { country: "Australia", phoneCode: "61", isoCode2: "AU", isoCode3: "AUS", lang: "en" },
  { country: "Austria", phoneCode: "43", isoCode2: "AT", isoCode3: "AUT", lang: "en" },
  { country: "Azerbaijan", phoneCode: "994", isoCode2: "AZ", isoCode3: "AZE", lang: "en" },
  { country: "Bahamas", phoneCode: "1 242", isoCode2: "BS", isoCode3: "BHS", lang: "en" },
  { country: "Bahrain", phoneCode: "973", isoCode2: "BH", isoCode3: "BHR", lang: "en" },
  { country: "Bangladesh", phoneCode: "880", isoCode2: "BD", isoCode3: "BGD", lang: "en" },
  { country: "Barbados", phoneCode: "1 246", isoCode2: "BB", isoCode3: "BRB", lang: "en" },
  { country: "Belarus", phoneCode: "375", isoCode2: "BY", isoCode3: "BLR", lang: "en" },
  { country: "Belgium", phoneCode: "32", isoCode2: "BE", isoCode3: "BEL", lang: "en" },
  { country: "Belize", phoneCode: "501", isoCode2: "BZ", isoCode3: "BLZ", lang: "en" },
  { country: "Benin", phoneCode: "229", isoCode2: "BJ", isoCode3: "BEN", lang: "en" },
  { country: "Bermuda", phoneCode: "1 441", isoCode2: "BM", isoCode3: "BMU", lang: "en" },
  { country: "Bhutan", phoneCode: "975", isoCode2: "BT", isoCode3: "BTN", lang: "en" },
  { country: "Bolivia", phoneCode: "591", isoCode2: "BO", isoCode3: "BOL", lang: "es" },
  { country: "Bosnia and Herzegovina", phoneCode: "387", isoCode2: "BA", isoCode3: "BIH", lang: "en" },
  { country: "Botswana", phoneCode: "267", isoCode2: "BW", isoCode3: "BWA", lang: "en" },
  { country: "Brazil", phoneCode: "55", isoCode2: "BR", isoCode3: "BRA", lang: "en" },
  { country: "British Indian Ocean Territory", phoneCode: "246", isoCode2: "IO", isoCode3: "IOT", lang: "en" },
  { country: "British Virgin Islands", phoneCode: "1 284", isoCode2: "VG", isoCode3: "VGB", lang: "en" },
  { country: "Brunei", phoneCode: "673", isoCode2: "BN", isoCode3: "BRN", lang: "en" },
  { country: "Bulgaria", phoneCode: "359", isoCode2: "BG", isoCode3: "BGR", lang: "en" },
  { country: "Burkina Faso", phoneCode: "226", isoCode2: "BF", isoCode3: "BFA", lang: "en" },
  { country: "Burundi", phoneCode: "257", isoCode2: "BI", isoCode3: "BDI", lang: "en" },
  { country: "Cambodia", phoneCode: "855", isoCode2: "KH", isoCode3: "KHM", lang: "en" },
  { country: "Cameroon", phoneCode: "237", isoCode2: "CM", isoCode3: "CMR", lang: "en" },
  { country: "Canada", phoneCode: "1", isoCode2: "CA", isoCode3: "CAN", lang: "en" },
  { country: "Cape Verde", phoneCode: "238", isoCode2: "CV", isoCode3: "CPV", lang: "en" },
  { country: "Cayman Islands", phoneCode: "1 345", isoCode2: "KY", isoCode3: "CYM", lang: "en" },
  { country: "Central African Republic", phoneCode: "236", isoCode2: "CF", isoCode3: "CAF", lang: "en" },
  { country: "Chad", phoneCode: "235", isoCode2: "TD", isoCode3: "TCD", lang: "en" },
  { country: "Chile", phoneCode: "56", isoCode2: "CL", isoCode3: "CHL", lang: "es" },
  { country: "China", phoneCode: "86", isoCode2: "CN", isoCode3: "CHN", lang: "en" },
  { country: "Christmas Island", phoneCode: "61", isoCode2: "CX", isoCode3: "CXR", lang: "en" },
  { country: "Cocos Islands", phoneCode: "61", isoCode2: "CC", isoCode3: "CCK", lang: "en" },
  { country: "Colombia", phoneCode: "57", isoCode2: "CO", isoCode3: "COL", lang: "es" },
  { country: "Comoros", phoneCode: "269", isoCode2: "KM", isoCode3: "COM", lang: "en" },
  { country: "Cook Islands", phoneCode: "682", isoCode2: "CK", isoCode3: "COK", lang: "en" },
  { country: "Costa Rica", phoneCode: "506", isoCode2: "CR", isoCode3: "CRI", lang: "es" },
  { country: "Croatia", phoneCode: "385", isoCode2: "HR", isoCode3: "HRV", lang: "en" },
  { country: "Cuba", phoneCode: "53", isoCode2: "CU", isoCode3: "CUB", lang: "es" },
  { country: "Curacao", phoneCode: "599", isoCode2: "CW", isoCode3: "CUW", lang: "en" },
  { country: "Cyprus", phoneCode: "357", isoCode2: "CY", isoCode3: "CYP", lang: "en" },
  { country: "Czech Republic", phoneCode: "420", isoCode2: "CZ", isoCode3: "CZE", lang: "en" },
  { country: "Democratic Republic of the Congo", phoneCode: "243", isoCode2: "CD", isoCode3: "COD", lang: "en" },
  { country: "Denmark", phoneCode: "45", isoCode2: "DK", isoCode3: "DNK", lang: "en" },
  { country: "Djibouti", phoneCode: "253", isoCode2: "DJ", isoCode3: "DJI", lang: "en" },
  { country: "Dominica", phoneCode: "1 767", isoCode2: "DM", isoCode3: "DMA", lang: "en" },
  { country: "Dominican Republic", phoneCode: "1 809, 1 829, 1 849", isoCode2: "DO", isoCode3: "DOM", lang: "es" },
  { country: "East Timor", phoneCode: "670", isoCode2: "TL", isoCode3: "TLS", lang: "en" },
  { country: "Ecuador", phoneCode: "593", isoCode2: "EC", isoCode3: "ECU", lang: "es" },
  { country: "Egypt", phoneCode: "20", isoCode2: "EG", isoCode3: "EGY", lang: "en" },
  { country: "El Salvador", phoneCode: "503", isoCode2: "SV", isoCode3: "SLV", lang: "es" },
  { country: "Equatorial Guinea", phoneCode: "240", isoCode2: "GQ", isoCode3: "GNQ", lang: "en" },
  { country: "Eritrea", phoneCode: "291", isoCode2: "ER", isoCode3: "ERI", lang: "en" },
  { country: "Estonia", phoneCode: "372", isoCode2: "EE", isoCode3: "EST", lang: "en" },
  { country: "Ethiopia", phoneCode: "251", isoCode2: "ET", isoCode3: "ETH", lang: "en" },
  { country: "Falkland Islands", phoneCode: "500", isoCode2: "FK", isoCode3: "FLK", lang: "en" },
  { country: "Faroe Islands", phoneCode: "298", isoCode2: "FO", isoCode3: "FRO", lang: "en" },
  { country: "Fiji", phoneCode: "679", isoCode2: "FJ", isoCode3: "FJI", lang: "en" },
  { country: "Finland", phoneCode: "358", isoCode2: "FI", isoCode3: "FIN", lang: "en" },
  { country: "France", phoneCode: "33", isoCode2: "FR", isoCode3: "FRA", lang: "en" },
  { country: "French Polynesia", phoneCode: "689", isoCode2: "PF", isoCode3: "PYF", lang: "en" },
  { country: "Gabon", phoneCode: "241", isoCode2: "GA", isoCode3: "GAB", lang: "en" },
  { country: "Gambia", phoneCode: "220", isoCode2: "GM", isoCode3: "GMB", lang: "en" },
  { country: "Georgia", phoneCode: "995", isoCode2: "GE", isoCode3: "GEO", lang: "en" },
  { country: "Germany", phoneCode: "49", isoCode2: "DE", isoCode3: "DEU", lang: "en" },
  { country: "Ghana", phoneCode: "233", isoCode2: "GH", isoCode3: "GHA", lang: "en" },
  { country: "Gibraltar", phoneCode: "350", isoCode2: "GI", isoCode3: "GIB", lang: "en" },
  { country: "Greece", phoneCode: "30", isoCode2: "GR", isoCode3: "GRC", lang: "en" },
  { country: "Greenland", phoneCode: "299", isoCode2: "GL", isoCode3: "GRL", lang: "en" },
  { country: "Grenada", phoneCode: "1 473", isoCode2: "GD", isoCode3: "GRD", lang: "en" },
  { country: "Guam", phoneCode: "1 671", isoCode2: "GU", isoCode3: "GUM", lang: "en" },
  { country: "Guatemala", phoneCode: "502", isoCode2: "GT", isoCode3: "GTM", lang: "es" },
  { country: "Guernsey", phoneCode: "44 1481", isoCode2: "GG", isoCode3: "GGY", lang: "en" },
  { country: "Guinea", phoneCode: "224", isoCode2: "GN", isoCode3: "GIN", lang: "en" },
  { country: "Guinea-Bissau", phoneCode: "245", isoCode2: "GW", isoCode3: "GNB", lang: "en" },
  { country: "Guyana", phoneCode: "592", isoCode2: "GY", isoCode3: "GUY", lang: "en" },
  { country: "Haiti", phoneCode: "509", isoCode2: "HT", isoCode3: "HTI", lang: "en" },
  { country: "Honduras", phoneCode: "504", isoCode2: "HN", isoCode3: "HND", lang: "es" },
  { country: "Hong Kong", phoneCode: "852", isoCode2: "HK", isoCode3: "HKG", lang: "en" },
  { country: "Hungary", phoneCode: "36", isoCode2: "HU", isoCode3: "HUN", lang: "en" },
  { country: "Iceland", phoneCode: "354", isoCode2: "IS", isoCode3: "ISL", lang: "en" },
  { country: "India", phoneCode: "91", isoCode2: "IN", isoCode3: "IND", lang: "en" },
  { country: "Indonesia", phoneCode: "62", isoCode2: "ID", isoCode3: "IDN", lang: "en" },
  { country: "Iran", phoneCode: "98", isoCode2: "IR", isoCode3: "IRN", lang: "en" },
  { country: "Iraq", phoneCode: "964", isoCode2: "IQ", isoCode3: "IRQ", lang: "en" },
  { country: "Ireland", phoneCode: "353", isoCode2: "IE", isoCode3: "IRL", lang: "en" },
  { country: "Isle of Man", phoneCode: "44 1624", isoCode2: "IM", isoCode3: "IMN", lang: "en" },
  { country: "Israel", phoneCode: "972", isoCode2: "IL", isoCode3: "ISR", lang: "en" },
  { country: "Italy", phoneCode: "39", isoCode2: "IT", isoCode3: "ITA", lang: "en" },
  { country: "Ivory Coast", phoneCode: "225", isoCode2: "CI", isoCode3: "CIV", lang: "en" },
  { country: "Jamaica", phoneCode: "1 876", isoCode2: "JM", isoCode3: "JAM", lang: "en" },
  { country: "Japan", phoneCode: "81", isoCode2: "JP", isoCode3: "JPN", lang: "en" },
  { country: "Jersey", phoneCode: "44 1534", isoCode2: "JE", isoCode3: "JEY", lang: "en" },
  { country: "Jordan", phoneCode: "962", isoCode2: "JO", isoCode3: "JOR", lang: "en" },
  { country: "Kazakhstan", phoneCode: "7", isoCode2: "KZ", isoCode3: "KAZ", lang: "en" },
  { country: "Kenya", phoneCode: "254", isoCode2: "KE", isoCode3: "KEN", lang: "en" },
  { country: "Kiribati", phoneCode: "686", isoCode2: "KI", isoCode3: "KIR", lang: "en" },
  { country: "Kosovo", phoneCode: "383", isoCode2: "XK", isoCode3: "XKX", lang: "en" },
  { country: "Kuwait", phoneCode: "965", isoCode2: "KW", isoCode3: "KWT", lang: "en" },
  { country: "Kyrgyzstan", phoneCode: "996", isoCode2: "KG", isoCode3: "KGZ", lang: "en" },
  { country: "Laos", phoneCode: "856", isoCode2: "LA", isoCode3: "LAO", lang: "en" },
  { country: "Latvia", phoneCode: "371", isoCode2: "LV", isoCode3: "LVA", lang: "en" },
  { country: "Lebanon", phoneCode: "961", isoCode2: "LB", isoCode3: "LBN", lang: "en" },
  { country: "Lesotho", phoneCode: "266", isoCode2: "LS", isoCode3: "LSO", lang: "en" },
  { country: "Liberia", phoneCode: "231", isoCode2: "LR", isoCode3: "LBR", lang: "en" },
  { country: "Libya", phoneCode: "218", isoCode2: "LY", isoCode3: "LBY", lang: "en" },
  { country: "Liechtenstein", phoneCode: "423", isoCode2: "LI", isoCode3: "LIE", lang: "en" },
  { country: "Lithuania", phoneCode: "370", isoCode2: "LT", isoCode3: "LTU", lang: "en" },
  { country: "Luxembourg", phoneCode: "352", isoCode2: "LU", isoCode3: "LUX", lang: "en" },
  { country: "Macau", phoneCode: "853", isoCode2: "MO", isoCode3: "MAC", lang: "en" },
  { country: "Macedonia", phoneCode: "389", isoCode2: "MK", isoCode3: "MKD", lang: "en" },
  { country: "Madagascar", phoneCode: "261", isoCode2: "MG", isoCode3: "MDG", lang: "en" },
  { country: "Malawi", phoneCode: "265", isoCode2: "MW", isoCode3: "MWI", lang: "en" },
  { country: "Malaysia", phoneCode: "60", isoCode2: "MY", isoCode3: "MYS", lang: "en" },
  { country: "Maldives", phoneCode: "960", isoCode2: "MV", isoCode3: "MDV", lang: "en" },
  { country: "Mali", phoneCode: "223", isoCode2: "ML", isoCode3: "MLI", lang: "en" },
  { country: "Malta", phoneCode: "356", isoCode2: "MT", isoCode3: "MLT", lang: "en" },
  { country: "Marshall Islands", phoneCode: "692", isoCode2: "MH", isoCode3: "MHL", lang: "en" },
  { country: "Mauritania", phoneCode: "222", isoCode2: "MR", isoCode3: "MRT", lang: "en" },
  { country: "Mauritius", phoneCode: "230", isoCode2: "MU", isoCode3: "MUS", lang: "en" },
  { country: "Mayotte", phoneCode: "262", isoCode2: "YT", isoCode3: "MYT", lang: "en" },
  { country: "Mexico", phoneCode: "52", isoCode2: "MX", isoCode3: "MEX", lang: "es" },
  { country: "Micronesia", phoneCode: "691", isoCode2: "FM", isoCode3: "FSM", lang: "en" },
  { country: "Moldova", phoneCode: "373", isoCode2: "MD", isoCode3: "MDA", lang: "en" },
  { country: "Monaco", phoneCode: "377", isoCode2: "MC", isoCode3: "MCO", lang: "en" },
  { country: "Mongolia", phoneCode: "976", isoCode2: "MN", isoCode3: "MNG", lang: "en" },
  { country: "Montenegro", phoneCode: "382", isoCode2: "ME", isoCode3: "MNE", lang: "en" },
  { country: "Montserrat", phoneCode: "1 664", isoCode2: "MS", isoCode3: "MSR", lang: "en" },
  { country: "Morocco", phoneCode: "212", isoCode2: "MA", isoCode3: "MAR", lang: "en" },
  { country: "Mozambique", phoneCode: "258", isoCode2: "MZ", isoCode3: "MOZ", lang: "en" },
  { country: "Myanmar", phoneCode: "95", isoCode2: "MM", isoCode3: "MMR", lang: "en" },
  { country: "Namibia", phoneCode: "264", isoCode2: "NA", isoCode3: "NAM", lang: "en" },
  { country: "Nauru", phoneCode: "674", isoCode2: "NR", isoCode3: "NRU", lang: "en" },
  { country: "Nepal", phoneCode: "977", isoCode2: "NP", isoCode3: "NPL", lang: "en" },
  { country: "Netherlands", phoneCode: "31", isoCode2: "NL", isoCode3: "NLD", lang: "en" },
  { country: "Netherlands Antilles", phoneCode: "599", isoCode2: "AN", isoCode3: "ANT", lang: "en" },
  { country: "New Caledonia", phoneCode: "687", isoCode2: "NC", isoCode3: "NCL", lang: "en" },
  { country: "New Zealand", phoneCode: "64", isoCode2: "NZ", isoCode3: "NZL", lang: "en" },
  { country: "Nicaragua", phoneCode: "505", isoCode2: "NI", isoCode3: "NIC", lang: "es" },
  { country: "Niger", phoneCode: "227", isoCode2: "NE", isoCode3: "NER", lang: "en" },
  { country: "Nigeria", phoneCode: "234", isoCode2: "NG", isoCode3: "NGA", lang: "en" },
  { country: "Niue", phoneCode: "683", isoCode2: "NU", isoCode3: "NIU", lang: "en" },
  { country: "North Korea", phoneCode: "850", isoCode2: "KP", isoCode3: "PRK", lang: "en" },
  { country: "Northern Mariana Islands", phoneCode: "1 670", isoCode2: "MP", isoCode3: "MNP", lang: "en" },
  { country: "Norway", phoneCode: "47", isoCode2: "NO", isoCode3: "NOR", lang: "en" },
  { country: "Oman", phoneCode: "968", isoCode2: "OM", isoCode3: "OMN", lang: "en" },
  { country: "Pakistan", phoneCode: "92", isoCode2: "PK", isoCode3: "PAK", lang: "en" },
  { country: "Palau", phoneCode: "680", isoCode2: "PW", isoCode3: "PLW", lang: "en" },
  { country: "Palestine", phoneCode: "970", isoCode2: "PS", isoCode3: "PSE", lang: "en" },
  { country: "Panama", phoneCode: "507", isoCode2: "PA", isoCode3: "PAN", lang: "es" },
  { country: "Papua New Guinea", phoneCode: "675", isoCode2: "PG", isoCode3: "PNG", lang: "en" },
  { country: "Paraguay", phoneCode: "595", isoCode2: "PY", isoCode3: "PRY", lang: "es" },
  { country: "Peru", phoneCode: "51", isoCode2: "PE", isoCode3: "PER", lang: "es" },
  { country: "Philippines", phoneCode: "63", isoCode2: "PH", isoCode3: "PHL", lang: "en" },
  { country: "Pitcairn", phoneCode: "64", isoCode2: "PN", isoCode3: "PCN", lang: "en" },
  { country: "Poland", phoneCode: "48", isoCode2: "PL", isoCode3: "POL", lang: "en" },
  { country: "Portugal", phoneCode: "351", isoCode2: "PT", isoCode3: "PRT", lang: "en" },
  { country: "Puerto Rico", phoneCode: "1 787, 1 939", isoCode2: "PR", isoCode3: "PRI", lang: "es" },
  { country: "Qatar", phoneCode: "974", isoCode2: "QA", isoCode3: "QAT", lang: "en" },
  { country: "Republic of the Congo", phoneCode: "242", isoCode2: "CG", isoCode3: "COG", lang: "en" },
  { country: "Reunion", phoneCode: "262", isoCode2: "RE", isoCode3: "REU", lang: "en" },
  { country: "Romania", phoneCode: "40", isoCode2: "RO", isoCode3: "ROU", lang: "en" },
  { country: "Russia", phoneCode: "7", isoCode2: "RU", isoCode3: "RUS", lang: "en" },
  { country: "Rwanda", phoneCode: "250", isoCode2: "RW", isoCode3: "RWA", lang: "en" },
  { country: "Saint Barthelemy", phoneCode: "590", isoCode2: "BL", isoCode3: "BLM", lang: "en" },
  { country: "Saint Helena", phoneCode: "290", isoCode2: "SH", isoCode3: "SHN", lang: "en" },
  { country: "Saint Kitts and Nevis", phoneCode: "1 869", isoCode2: "KN", isoCode3: "KNA", lang: "en" },
  { country: "Saint Lucia", phoneCode: "1 758", isoCode2: "LC", isoCode3: "LCA", lang: "en" },
  { country: "Saint Martin", phoneCode: "590", isoCode2: "MF", isoCode3: "MAF", lang: "en" },
  { country: "Saint Pierre and Miquelon", phoneCode: "508", isoCode2: "PM", isoCode3: "SPM", lang: "en" },
  { country: "Saint Vincent and the Grenadines", phoneCode: "1 784", isoCode2: "VC", isoCode3: "VCT", lang: "en" },
  { country: "Samoa", phoneCode: "685", isoCode2: "WS", isoCode3: "WSM", lang: "en" },
  { country: "San Marino", phoneCode: "378", isoCode2: "SM", isoCode3: "SMR", lang: "en" },
  { country: "Sao Tome and Principe", phoneCode: "239", isoCode2: "ST", isoCode3: "STP", lang: "en" },
  { country: "Saudi Arabia", phoneCode: "966", isoCode2: "SA", isoCode3: "SAU", lang: "en" },
  { country: "Senegal", phoneCode: "221", isoCode2: "SN", isoCode3: "SEN", lang: "en" },
  { country: "Serbia", phoneCode: "381", isoCode2: "RS", isoCode3: "SRB", lang: "en" },
  { country: "Seychelles", phoneCode: "248", isoCode2: "SC", isoCode3: "SYC", lang: "en" },
  { country: "Sierra Leone", phoneCode: "232", isoCode2: "SL", isoCode3: "SLE", lang: "en" },
  { country: "Singapore", phoneCode: "65", isoCode2: "SG", isoCode3: "SGP", lang: "en" },
  { country: "Sint Maarten", phoneCode: "1 721", isoCode2: "SX", isoCode3: "SXM", lang: "en" },
  { country: "Slovakia", phoneCode: "421", isoCode2: "SK", isoCode3: "SVK", lang: "en" },
  { country: "Slovenia", phoneCode: "386", isoCode2: "SI", isoCode3: "SVN", lang: "en" },
  { country: "Solomon Islands", phoneCode: "677", isoCode2: "SB", isoCode3: "SLB", lang: "en" },
  { country: "Somalia", phoneCode: "252", isoCode2: "SO", isoCode3: "SOM", lang: "en" },
  { country: "South Africa", phoneCode: "27", isoCode2: "ZA", isoCode3: "ZAF", lang: "en" },
  { country: "South Korea", phoneCode: "82", isoCode2: "KR", isoCode3: "KOR", lang: "en" },
  { country: "South Sudan", phoneCode: "211", isoCode2: "SS", isoCode3: "SSD", lang: "en" },
  { country: "Spain", phoneCode: "34", isoCode2: "ES", isoCode3: "ESP", lang: "es" },
  { country: "Sri Lanka", phoneCode: "94", isoCode2: "LK", isoCode3: "LKA", lang: "en" },
  { country: "Sudan", phoneCode: "249", isoCode2: "SD", isoCode3: "SDN", lang: "en" },
  { country: "Suriname", phoneCode: "597", isoCode2: "SR", isoCode3: "SUR", lang: "en" },
  { country: "Svalbard and Jan Mayen", phoneCode: "47", isoCode2: "SJ", isoCode3: "SJM", lang: "en" },
  { country: "Swaziland", phoneCode: "268", isoCode2: "SZ", isoCode3: "SWZ", lang: "en" },
  { country: "Sweden", phoneCode: "46", isoCode2: "SE", isoCode3: "SWE", lang: "en" },
  { country: "Switzerland", phoneCode: "41", isoCode2: "CH", isoCode3: "CHE", lang: "en" },
  { country: "Syria", phoneCode: "963", isoCode2: "SY", isoCode3: "SYR", lang: "en" },
  { country: "Taiwan", phoneCode: "886", isoCode2: "TW", isoCode3: "TWN", lang: "en" },
  { country: "Tajikistan", phoneCode: "992", isoCode2: "TJ", isoCode3: "TJK", lang: "en" },
  { country: "Tanzania", phoneCode: "255", isoCode2: "TZ", isoCode3: "TZA", lang: "en" },
  { country: "Thailand", phoneCode: "66", isoCode2: "TH", isoCode3: "THA", lang: "en" },
  { country: "Togo", phoneCode: "228", isoCode2: "TG", isoCode3: "TGO", lang: "en" },
  { country: "Tokelau", phoneCode: "690", isoCode2: "TK", isoCode3: "TKL", lang: "en" },
  { country: "Tonga", phoneCode: "676", isoCode2: "TO", isoCode3: "TON", lang: "en" },
  { country: "Trinidad and Tobago", phoneCode: "1 868", isoCode2: "TT", isoCode3: "TTO", lang: "en" },
  { country: "Tunisia", phoneCode: "216", isoCode2: "TN", isoCode3: "TUN", lang: "en" },
  { country: "Turkey", phoneCode: "90", isoCode2: "TR", isoCode3: "TUR", lang: "en" },
  { country: "Turkmenistan", phoneCode: "993", isoCode2: "TM", isoCode3: "TKM", lang: "en" },
  { country: "Turks and Caicos Islands", phoneCode: "1 649", isoCode2: "TC", isoCode3: "TCA", lang: "en" },
  { country: "Tuvalu", phoneCode: "688", isoCode2: "TV", isoCode3: "TUV", lang: "en" },
  { country: "U.S. Virgin Islands", phoneCode: "1 340", isoCode2: "VI", isoCode3: "VIR", lang: "en" },
  { country: "Uganda", phoneCode: "256", isoCode2: "UG", isoCode3: "UGA", lang: "en" },
  { country: "Ukraine", phoneCode: "380", isoCode2: "UA", isoCode3: "UKR", lang: "en" },
  { country: "United Arab Emirates", phoneCode: "971", isoCode2: "AE", isoCode3: "ARE", lang: "en" },
  { country: "United Kingdom", phoneCode: "44", isoCode2: "GB", isoCode3: "GBR", lang: "en" },
  { country: "United States", phoneCode: "1", isoCode2: "US", isoCode3: "USA", lang: "en" },
  { country: "Uruguay", phoneCode: "598", isoCode2: "UY", isoCode3: "URY", lang: "es" },
  { country: "Uzbekistan", phoneCode: "998", isoCode2: "UZ", isoCode3: "UZB", lang: "en" },
  { country: "Vanuatu", phoneCode: "678", isoCode2: "VU", isoCode3: "VUT", lang: "en" },
  { country: "Vatican", phoneCode: "379", isoCode2: "VA", isoCode3: "VAT", lang: "en" },
  { country: "Venezuela", phoneCode: "58", isoCode2: "VE", isoCode3: "VEN", lang: "es" },
  { country: "Vietnam", phoneCode: "84", isoCode2: "VN", isoCode3: "VNM", lang: "en" },
  { country: "Wallis and Futuna", phoneCode: "681", isoCode2: "WF", isoCode3: "WLF", lang: "en" },
  { country: "Western Sahara", phoneCode: "212", isoCode2: "EH", isoCode3: "ESH", lang: "en" },
  { country: "Yemen", phoneCode: "967", isoCode2: "YE", isoCode3: "YEM", lang: "en" },
  { country: "Zambia", phoneCode: "260", isoCode2: "ZM", isoCode3: "ZMB", lang: "en" },
  { country: "Zimbabwe", phoneCode: "263", isoCode2: "ZW", isoCode3: "ZW", lang: "en" }
]