




import { Component, Vue } from 'vue-property-decorator';
import AppMain from '@/views/layouts/AppMain.vue';

@Component({ components: { AppMain } })
export default class App extends Vue {}
