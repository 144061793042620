import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const MY_ICONS = {
  add: 'mdi-plus',
  cancel: 'mdi-cancel',
  close: 'mdi-close'
}

export default new Vuetify({
  icons: {
    values: MY_ICONS
  },
  theme: {
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: '#26C6DA',
        secondary: '#9C27B0',
        accent: '#03DAC5',
        error: '#721C24',
        info: '#2196F3',
        success: '#155724',
        warning: '#856404',
      }
    }
  }
})
