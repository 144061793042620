










































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Header extends Vue {
  drawer = false
  items = [
    { title: 'home', url: '/' },
    // { title: 'portfolio', url: '/portfolio' },
    // { title: 'about', url: '/about' },
    { title: 'contact', url: '/contact' },
  ]
  right = null
}
