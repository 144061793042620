










import { Component, Emit, Vue } from 'vue-property-decorator';

@Component
export default class LangSwitch extends Vue {
  @Emit()
  isLocaleEn() {
    return this.$root.$i18n.locale == 'en';
  }

  isLocaleEs() {
    return this.$root.$i18n.locale == 'es';
  }

  @Emit()
  switchLang() {
    // this.$root.$i18n.locale = this.isLocaleEn() ? 'es' : 'en';

    this.$root.$i18n.locale = this.isLocaleEs() ? 'en' : 'es';
  }
}
