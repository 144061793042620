import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueGtag from 'vue-gtag'

const GTAG_CONFIG = {
  config: { id: "G-TCLNYL7MN9" }
}

Vue.use(VueGtag, GTAG_CONFIG, router)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
